import React from "react";
import { observer } from "mobx-react-lite"
import isEmpty from 'lodash/isEmpty';

import {useStyles} from "./styles";

export const Layout = observer(({ children, isProgressBarActive, pageConfigFile, isScrollableContent }: any) => {
    const isNextButtonExist = !isEmpty(pageConfigFile?.button?.base);
    const { content } = useStyles({ isProgressBarActive, isNextButtonExist, isScrollableContent });

    return (
        <>
            <div className={content}>
                {children}
            </div>
        </>
    )
});
