import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    contentWrapper: {
        marginTop: 24
    },
    tabClass: {
        '& p': {
            color: '#202B47'
        }
    }
});
