import React, { useEffect, useContext } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';

import { Layout } from '../../components/layout';
import { Generic } from '../../components/generic';
import { GenericInstanceCTX } from '../../store/generic_store';

import { componentConfigFile } from '../../utils/component_config_file';
import { BaseContext } from '../../helpers/context/base.context';

const PATH_PREVIEW = '/preview';

const MemoComponent = React.memo(({data}: any) => (
  <>
  <Layout
    isProgressBarActive={!data?.disable_progress_bar}
    activeDefaultLayout={data?.default_layout_active}
    activeCenteredLayout={data?.default_centered_layout}
  >
    <Generic pageConfigFile={data} componentConfigFile={componentConfigFile} />
  </Layout>
</>
), ({data}: any, {data: nextData}: any) => {
  return data?.path === nextData?.path;
})

export const GenericPage = observer(() => {
  const { path } = useParams<{ path: string }>();
  const { analytic } = useContext(BaseContext);
  const { getLazyGenericDataFirebase, preloadedData, pageDataNotExist }: any =
    useContext(GenericInstanceCTX);
  const data = toJS(preloadedData)


  useEffect(() => {
    if (window.location.pathname.includes(PATH_PREVIEW)) {
      const parsed = queryString.parse(window.location.search);
      return getLazyGenericDataFirebase({ id: parsed.previewID });
    }
  }, []);

  useEffect(() => {
    if (path) getLazyGenericDataFirebase({ path });
  }, [path]);

  useEffect(() => {
    if (data[path]?.viewedEvents) {
      data[path]?.viewedEvents.forEach((eventKey: string) => {
        analytic?.event(eventKey);
      });
    }
  }, [path]);

  if (pageDataNotExist) return <Redirect to="/" />;

  return <MemoComponent data={data[path]} />;
});
