import React, {useContext} from 'react';
import { observer } from "mobx-react";

import { toJS } from 'mobx';

import { Animation } from '../animation';
import { GenericInstanceCTX } from '../../store/generic_store';

const Animated = React.memo(({ type, src, title, settings, preloadedImage }: any) => (
  <>
    {type === 'img' ? <img onLoad={() => {console.log('loaded')}} src={ preloadedImage || src } alt={title} {...settings} /> : null}
    {type === 'animation' && preloadedImage ? (
      <Animation
        {...{
          [!preloadedImage ? 'url' : 'src']: src || preloadedImage,
          src: preloadedImage,
          ...settings,
        }}
      />
    ) : null}
  </>
));

export const ThumbnailFactory = observer(({ img: { src, title, type, ...settings } }: any) => {
  const { getMediaByPath } = useContext(GenericInstanceCTX);

  const preloadedImage = toJS(getMediaByPath(window.location.pathname.replace('/', '')));

  return <Animated {...{ type, src, title, settings, preloadedImage }}/>
});
