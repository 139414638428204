import { createContext } from 'react';
import { matchPath } from "react-router-dom";

import { localStorageService } from '@ruby-labs/ruby-web-common';
import axios from 'axios';
import { makeAutoObservable } from 'mobx';

import { BASIC_FUNNEL_VARIATION_KEY_NAME } from '../constants/base';

import { ConfigPageService, PageConfigType } from '../helpers/services/config_page.service';
import { ableCustomRoutes, funnelAB } from '../components/app';
import { analytic } from '../index';

const getVariation = (variation?: string | null) => {
  return variation || localStorageService.getItem(BASIC_FUNNEL_VARIATION_KEY_NAME) || null
};

class Generic {
  preloadedData: { [key: string]: any } = {};
  preloadedProgress: { [key: string]: any } = {};
  preloadedMedia: { [key: string]: any } = {};
  progressLoading: boolean = false;
  pageDataNotExist = false;
  private pageService = new ConfigPageService();

  getLazyGenericDataFirebase = async ({ path, id, variation }: PageConfigType) => {
    if (this.preloadedData[path as string] && this.preloadedProgress[path as string]) {
      return;
    }
    try {
      const data = funnelAB.mergeIntersectedConfig(
        path as string,
        await this.pageService.getConfigPage({
          path,
          id,
          variation: getVariation(variation),
        }),
        analytic.optyConfigRef
      );

      const progress = await this.pageService.getProgressBar({
        page_config_id: data?.id,
        variation: getVariation(data?.progress_variant)
      });

      if (progress?.progress_bar) this.preloadedProgress[path as string] = progress?.progress_bar;
      if (!progress?.progress_bar) {
        const progressDefault = await this.pageService.getProgressBar({
          page_config_id: data?.id,
          variation: null,
        });
        this.preloadedProgress[path as string] = progressDefault?.progress_bar;
      }

      this.preloadedData[path as string] = data;
      setTimeout(() => {this.progressLoading = false;}, 10)
    } catch (e) {
      this.pageDataNotExist = true;
      setTimeout(() => {this.progressLoading = false;}, 10)
    }
  };

  startPreloadPage = async (destinationPath: string) => {
    const path = destinationPath.replace('/', '');

    this.preloadRouteComponent(destinationPath);

    if (this.preloadedData[path]) return;
    try {
      const data = funnelAB.mergeIntersectedConfig(
        path as string,
        await this.pageService.getConfigPage({
          path,
          variation: getVariation(null),
        }),
        analytic.optyConfigRef
      );

      await this.preloadMedia(data?.thumbnails?.img?.src, path);

      const progress = await this.pageService.getProgressBar({
        page_config_id: data?.id,
        variation: getVariation(data?.progress_variant),
      });
      this.preloadedData[path] = data;
      if (progress?.progress_bar) this.preloadedProgress[path] = progress.progress_bar;
      else {
        const progressDefault = await this.pageService.getProgressBar({
          page_config_id: data?.id,
          variation: null,
        });
        this.preloadedProgress[path as string] = progressDefault?.progress_bar;
      }
    } catch (e) {
      this.preloadedData[path] = null;
    }
  };

  findComponentForRoute = (path: string, routes: any[]) => {
    const matchingRoute = routes.find(route =>
        matchPath(path, {
          path: route.path,
          exact: route.exact
        })
    );
    return matchingRoute ? matchingRoute.component : null;
  };

  preloadRouteComponent = (path: string) => {
    const component = this.findComponentForRoute(path, ableCustomRoutes);
    if (component && component.preload) {
      component.preload();
    }
  };

  getMediaByPath = (path: string) => {
    return this.preloadedMedia[path];
  };

  preloadMedia = async (img: any, path: string) => {
    if (!img || !path || this.preloadedMedia[path]) {
      return;
    }

    axios.get(img, {responseType: 'blob'}).then(({ data }) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => { this.preloadedMedia[path] = reader.result; };
    });
  };

  loadAllMedia = async () => {
    (await this.pageService.getAllMedia()).map((item: any) => {
      if (item.thumbnails.img.type === 'animation') {
        axios.get(item.thumbnails.img.src).then(({ data }) => {
          this.preloadedMedia[item.path] = data;
        });
      } else {
        const img: any = new Image();
        img.src = item.thumbnails.img.src;
        this.preloadedMedia[item.path] = img?.default;
      }
    })
  }

  constructor() {
    makeAutoObservable(this);
    // this.loadAllMedia();
  }
}

export const GenericInstance = new Generic();
export const GenericInstanceCTX = createContext(GenericInstance);
