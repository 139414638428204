import { localStorageService } from "@ruby-labs/ruby-web-common";

const LOCALSTORADGE_WATCHING_KEY = "body-idealweight";
const watchVP = localStorageService.intitWatchVP(LOCALSTORADGE_WATCHING_KEY);

export const BodyIdealWeightInitVP = () => {
    watchVP([
        (value: string) => {
            const newKey = "habits-ligestyle-vp";
            const unit = localStorageService.getItem('weight_unit_of_measurement_tab')

            const newValue = `reach an ideal weight between ${parseInt(value) - 10} ${unit} and ${parseInt(value) + 10} ${unit}`

            localStorageService.setItem(newKey, newValue);
        },
    ]);
};
