const SPECIFIC_SYSTEMS = {
    US: { weight: 'lb', height: 'ft and in' },
    LR: { weight: 'lb', height: 'ft and in' },
    MM: { weight: 'lb', height: 'ft and in' }
};

const DEFAULT_SYSTEM = { weight: 'kg', height: 'cm'}

const getAdditionalKey = (tabs: any = [], systemKey: string) => {
    const country = localStorage.getItem('utm_country');
    // @ts-ignore
    const system = SPECIFIC_SYSTEMS[country] ? SPECIFIC_SYSTEMS[country] : DEFAULT_SYSTEM;
    // @ts-ignore
    const index = tabs.findIndex((item: string) => item === system[systemKey]);

    return index > 0 ? index : 0;
}

export const getIndexOfDefaultTab = (tabs: any = {}, defaultTabData: string | number) => {
    let defaultTabIndex = tabs.tabs?.findIndex((item: string) => item === defaultTabData);

    if (defaultTabIndex < 0) {
        const { systemKey } = tabs;

        return systemKey ? getAdditionalKey(tabs.tabs, systemKey) : 0;
    }

    return defaultTabIndex;
}