import React from 'react';
import { useLocalStorage } from "@ruby-labs/ruby-web-common";

import { BaseInput } from "../base_input";

export const Content = ({ type, storeKey, style, path, tabKey, tabs, onError, ...input }: any) => {
    const { pageValue, setPageValue } = useLocalStorage({ key: storeKey, defaultValue: '' });

    const onChange = (e: any) => {
        setPageValue(e?.target?.value)
    }

    return (
        <div style={style}>
            <BaseInput
                tabKey={tabKey}
                storeKey={storeKey}
                path={path}
                onChange={onChange}
                onError={onError}
                value={pageValue}
                {...input}
            />
        </div>
    )
}
