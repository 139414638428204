import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    rootValidatedInputClass: {},
    '@media (max-width: 786px)': {
        rootValidatedInputClass: {
            '& > p': {
                display: 'none'
            }
        }
    }
})
