import {useEffect, useState} from "react";
import queryString from "query-string";
import {getCookie} from "../../utils/cookie";
import {RedirectApiProvider} from "../../api/redirect.api";

export const useRedirect = () => {
    const [isWeb, setWebView] = useState(false);
    const [count, setCount] = useState(0);
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent.toLowerCase();
    const { redirected } = queryString.parse(window.location.search);

    const handleCheckWebView = () => {
        // @ts-ignore
        const standalone = window.navigator?.standalone;
        const safari = /safari/.test(userAgent);
        const ios = /iphone|ipod|ipad/.test(userAgent);
        const isInFacebook = userAgent.indexOf('fban') > -1 || userAgent.indexOf('fbav') > -1;
        const isInInstagram = userAgent.includes('wv') && userAgent.includes('instagram');

        if (ios) {
            if (!standalone && safari) {
                return false;
            }

            return !(!standalone && !safari && isInFacebook && count === 1)
        }

        if (isInInstagram) {
            return !redirected;
        }

        if (isInFacebook) {
            return false;
        }

        return false;
    }

    useEffect(() => {
        const isWeb = handleCheckWebView();

        setWebView(isWeb);
    }, []);

    return {
        isWeb, setCount, count,
        handleRedirectToBrowser: (goal: any) => {
            const { search, hostname, href } = window.location;
            const isIOS = Boolean(userAgent.match(/iphone|ipad|ipod/i));
            const userId = localStorage.getItem('userID');
            const cid = localStorage.getItem('cid') || sessionStorage.getItem('cid') || getCookie('cid');
            const query = `showSecondPage=true&domain=${hostname}&userId=${userId}&goal=${goal}&redirected=true&_fbp=${getCookie('_fbp')}&_fbc=${getCookie('_fbc')}&cid=${cid}`;

            if (!isIOS) {
                RedirectApiProvider.getRedirectUrl(encodeURIComponent(href), query);
            } else {
                const link = document.createElement('a');
                const querySearch = search ? search + '&' + query : '?' + query;

                link.href = `ftp://file.hint.app/redirect.html${querySearch}`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
}
