export type handerType = (value: { [key: string]: string }) => {
  [key: string]: string;
};

type handlersType = {
  [key: string]: handerType;
};

export class FunnelABTestingService {
  features: string[] = [];
  handlers: handlersType = {};

  constructor(features: string[], handlers: handlersType = {}) {
    this.features = features;
    this.handlers = handlers;
  }

  mergeIntersectedConfig(route: string, config: any, configsOpty: any): any {
    if (!this.handlers && !Object.keys(this.handlers).length) return;

    for (const feature in configsOpty) {
      const abTestData = this.handlers[feature](configsOpty[feature]);

      if (abTestData && abTestData[route]) {
        return mergeDeep(config, abTestData[route]);
      }
    }

    return config;
  }
}

export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export default function mergeDeep(target: any, source: any) {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        console.log({ key, source, value: Object.assign(output, { [key]: source[key] }) });
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}
