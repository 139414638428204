import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  header: {
    padding: '16px 21px 7px',
    textAlign: 'center',
    minHeight: 58,
    borderBottom: '1px solid #DEE5F9',
  },
  titleClass: {
    fontWeight: 600,
    marginTop: 4,
    '& + img': {
      top: 28
    }
  }
});
