import React from 'react';
import shortid from 'shortid';
import parser from 'html-react-parser';
import { Typography, TextNormalizer } from '@ruby-labs/ruby-web-common';

import { destinationService } from '../app';
import { styleParser } from "../../helpers/services/config_styles_parser.service";


export const TypographiesFactory = ({ order, ...typographiesConfig }: any) => {
    const data = typeof typographiesConfig === 'object' ? Object.values(typographiesConfig) : typographiesConfig

    return data?.map(({ variant, text, ...typography }: any) => {
        typography.style = styleParser(typography.style);

        return (
            <Typography
                key={shortid.generate()}
                {...typography}
                variant={variant}
            >
                {parser(
                    TextNormalizer(
                        destinationService.parse({ destination: text.replace('<p>', '').replace('</p>', '') }),
                        {
                            variables: {},
                            brChanger: true,
                            localStorageMode: true,
                            tagWrappers: [['*', 'p'], ['$', 'div']]
                        }) || ''
                )}
            </Typography>
        )
    })
}
