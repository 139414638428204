import { handerType } from '../index';

export const onboardingGeneralLayout: handerType = (value) => {
    const { layout } = value;

    return {
        '1': {
            'body-idealweight': require('./body-idealweight.json'),
            'body-weight': require('./body-weight.json'),
            'body-idealweightlasttime': require('./body-idealweightlasttime.json'),
            'nutrition-pace': require('./nutrition-pace.json'),
            'body-shared': require('./body-shared.json'),
            'nutrition-smallgoal': require('./nutrition-smallgoal.json'),
            'nutrition-water': require('./nutrition-water.json'),
            'habits-activity': require('./habits-activity.json'),
        }
    }[layout] as {};
};
