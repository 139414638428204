import React, {useContext, useEffect} from "react";
import {useLocation, useHistory} from "react-router-dom";
import {withOptimizely} from "@optimizely/react-sdk";
import queryString from "query-string";

import { Layout } from "../layout";
import { EmailHeader } from "../email_header";
import { ProgressBarLayout } from '../progress_bar_layout';

import {
    ROUTES_WITH_HEADER,
    ROUTES_WITHOUT_MARGINS,
    ROUTES_WITH_PROGRESS_BAR,
    ROUTES_WITH_SCROLL
} from "../../constants/layout";
import {useAccessPageChecking} from "../../helpers/hooks/access_page_checking.hook";
import {useLocalStorage} from "@ruby-labs/ruby-web-common";
import {createCookie} from "../../utils/cookie";
import {BaseContext} from "../../helpers/context/base.context";
import { useStyles } from "./styles";

export const MainContent = withOptimizely(({ children, optimizely }: any) => {
    const history = useHistory();
    const { pathname, search } = useLocation();
    const { routerAvailable } = useAccessPageChecking()
    const { mainContent } = useStyles({
        props: pathname === '/',
        isScrollableContent: ROUTES_WITH_SCROLL.includes(pathname)
    });
    const { analytic } = useContext(BaseContext);
    const { setPageValue: setGoal }: any = useLocalStorage({ key: 'body-goal', defaultValue: '' });
    const { setPageValue: setCid } = useLocalStorage({ key: 'cid', defaultValue: '' });

    useEffect(() => {
        optimizely?.onReady().then(() => {
            const {layout} = optimizely.getFeatureVariables('web_final_loading_story');
            const isEnabled = optimizely.isFeatureEnabled('web_final_loading_story');

            //useLocalStorage doesn't work with this feature on local
            localStorage.setItem('ab_finisher', isEnabled ? layout : 0);

        });
    }, [])

    useEffect(() => {
        const {
            showSecondPage,
            userId,
            goal,
            _fbp,
            _fbc,
            cid
        } = queryString.parse(search);

        if (cid) {
            setCid(cid)
            sessionStorage.setItem('cid', cid as string)
            createCookie('cid', cid as string)
        }

        if (showSecondPage) {
            if (!!userId) {
                analytic.userId = userId as string;
                analytic.setAmplitudeUserId(userId);
            }

            if (goal) {
                setGoal(goal)
            }

            if (_fbp) {
                createCookie('_fbp', _fbp as string)
            }

            if (_fbc) {
                createCookie('_fbc', _fbc as string)
            }

            history?.push("body-height")
        } else {
            if (window.location.pathname === '/') {
                analytic.event('WebStartViewed');
            }
        }
    }, [history]);

    useEffect(() => {
        window.addEventListener("storage", () => {
            if (Object.keys(localStorage).length === 0) {
                window.location.reload();
            }
        });
    }, []);

    useEffect(() => {
        window.dataLayer.push({ event: 'optimize.activate' });
    });

    const isHeaderVisible = ROUTES_WITH_HEADER.includes(pathname)
    const isWrapperMainContentActive = !ROUTES_WITHOUT_MARGINS.includes(pathname)
    const isProgressBarActive = ROUTES_WITH_PROGRESS_BAR.includes(pathname)

    if(!routerAvailable) return null

    return (
        <>
            {isHeaderVisible ? <EmailHeader /> : null}
            {
                isWrapperMainContentActive ? (
                    <div className={mainContent}>
                        <ProgressBarLayout/>
                        <Layout
                            isProgressBarActive={isProgressBarActive}
                        >
                            {children}
                        </Layout>
                    </div>
                ) : children
            }
        </>
    )
})
