import React, { useRef } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import { useStyles } from './styles'

export const Animation = ({ url, width, height, ...other }: any) => {
    const ref: any = useRef(null)
    const { loop } = other
    const style = {
        width: width ? `${width}px` : 'auto',
        height: height ? `${height}px` : 'auto'
    }
    const { animation } = useStyles()

    function getInstance() {
        return ref.current?.state?.instance
    }

    function onEvent(e: any) {
        if(localStorage.getItem('animationStopped')) {
            const instance = getInstance();
            if (instance?.currentFrame === 0) {
                instance?.goToAndStop(instance.totalFrames - 1, true);
            }
        }
        if (loop) return

        switch (e) {
            case 'frame': {
                const instance = getInstance()
                if (instance?.currentFrame === 0) {
                    instance?.goToAndStop(instance.totalFrames - 1, true)
                }
                break
            }
        }
    }
    
    return <div className={animation}>
        <Player ref={ref} autoplay mode="normal" {...other} style={style} src={url} onEvent={onEvent} />
    </div>
}
