import {WrapperApi} from "./wrapper.api";

class ShareInfo extends WrapperApi{

    shareUserInfo(data: any): Promise<any> {
        return fetch(
            `${process.env.REACT_APP_UPDATED_API_URL}/api/v1/signup/user-shared-info`,
            {
                method: 'POST',
                body: JSON.stringify(data)
            }
        ).then(res => res.json()).catch((e) => console.log(e));
    }

    getUserData() {
        return fetch(`${process.env.REACT_APP_GEOCODING_API_URL}`, { method: 'GET' })
            .then((response) => response.json())
            .catch((e) => console.log(e));
    }
}

export const ShareInfoProvider = new ShareInfo();
