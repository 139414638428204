import { localStorageService } from "@ruby-labs/ruby-web-common";

const LOCALSTORADGE_WATCHING_KEY = "body-age";
const watchVP = localStorageService.intitWatchVP(LOCALSTORADGE_WATCHING_KEY);

export const BodyAgeInitVP = () => {
    watchVP([
        (value: string) => {
            const newKey = "body-shared-age";
            const newValue = Number.parseInt(value) >= 20 ? `in their ${value}` : value;
            localStorageService.setItem(newKey, newValue);
        },
    ]);
};
