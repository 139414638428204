import React, {useEffect, useState} from 'react';
import { Tab, useLocalStorage } from "@ruby-labs/ruby-web-common";
import values from 'lodash/values';

import { Content } from "./content";

import { isTabsExists } from "../../utils/buttons_conditions";
import { getIndexOfDefaultTab } from "../../utils/form_getters";
import { isTabVisible } from "../../utils/form_conditions";
import { useStyles } from "./styles";

export const GroupFormFactory = ({ tabs, storeKey, list, path, ...otherProps }: any) => {
    const { contentWrapper, tabClass } = useStyles();
    const { setPageValue: setPageError } = useLocalStorage({ key: `${path}_error`, defaultValue: false });
    const { pageValue, setPageValue } = useLocalStorage({ key: path, defaultValue: '' });
    const [errors, setError] = useState({});
    const [tab, setTab] = useState((() => {
        if (!isTabsExists(tabs)) return;

        return getIndexOfDefaultTab(tabs, getTabData(storeKey + '_unit_of_measurement'));
    })());

    useEffect(() => {
        if (isTabsExists(tabs)) {
            setTabData(storeKey + '_unit_of_measurement', tabs?.tabs[tab]);
        }
    }, [tab])

    const onError = (error: any, key: any) => {
        // @ts-ignore
        errors[key] = error;

        const isError = values(errors).some(item => item);

        setPageError(isError);
        setError(errors);
    }

    const onTabChange = (tab: any) => {
        const { coef } = tabs;

        if (coef) {
            const nextUnit = tabs.tabs[tab];
            const prevUnit = getTabData(storeKey + '_unit_of_measurement');

            if(nextUnit !== prevUnit && pageValue) {
                setPageValue(Math.round(coef[nextUnit] * pageValue));
            }
        }

        setTimeout(() => setTab(tab), 0);
    };

    let inputs;
    let style = {};

    const isTabExists = isTabVisible(tabs);

    if(isTabExists) {
        const tabInputs = otherProps[tabs.tabs[tab]];

        inputs = tabInputs.list;
        style = tabInputs.style;
    } else {
        inputs = list;
    }

    return (
        <>
            {isTabExists && (
                <Tab
                    tabClass={tabClass}
                    onChange={onTabChange}
                    {...tabs}
                    activeTab={tab}
                />
            )}
            <div className={contentWrapper} style={style}>
                {inputs.map(({ ...otherProps }: any, i: number) =>
                    <Content
                        tabs={tabs}
                        onError={(error: any) => onError(error, otherProps.errorKey)}
                        tabKey={tab}
                        key={i}
                        path={path}
                        {...otherProps}
                    />
                )}
            </div>
        </>
    )
}

export function getTabData (key: string): number | string {
    return localStorage.getItem(key + '_tab') || 0
}

export function setTabData (key: string, data: any) {
    localStorage.setItem(key + '_tab', data)
}
