import merge from 'lodash/merge';

import { handerType } from '../index';

function getByNewIllustration(new_illustration: string) {
    if(new_illustration && new_illustration !== '0') {
        return {
            '1': {
                'body-shared': require('./new_illustration/variation_2/body-shared.json'),
                'body-congrats': require('./new_illustration/variation_2/body-congrats.json'),
                'body-customizeplan': require('./new_illustration/variation_2/body-customizeplan.json'),
                'habits-goal': require('./new_illustration/variation_2/habits-goal.json'),
                'habits-goalwell': require('./new_illustration/variation_2/habits-goalwell.json'),
                'habits-exercise-not-required-female': require('./new_illustration/variation_2/habits-exercise-not-required-female.json'),
                'habits-exercise-not-required-male': require('./new_illustration/variation_2/habits-exercise-not-required-male.json'),
                'habits-plan': require('./new_illustration/variation_2/habits-plan.json'),
                'habits-changes': require('./new_illustration/variation_2/habits-changes.json'),
                'habits-changes-male': require('./new_illustration/variation_2/habits-changes-male.json'),
                'habits-motivation': require('./new_illustration/variation_2/habits-motivation.json'),
                'habits-busy': require('./new_illustration/variation_2/habits-busy.json'),
                'nutrition-start': require('./new_illustration/variation_2/nutrition-start.json'),
                'nutrition-cbtfeedbackgreat': require('./new_illustration/variation_2/nutrition-cbtfeedbackgreat.json'),
                'nutrition-cbtfeedbackperfect': require('./new_illustration/variation_2/nutrition-cbtfeedbackperfect.json'),
                'nutrition-cbtfeedbackcuriosity': require('./new_illustration/variation_2/nutrition-cbtfeedbackcuriosity.json'),
                'nutrition-control': require('./new_illustration/variation_2/nutrition-control.json'),
                'nutrition-plan': require('./new_illustration/variation_2/nutrition-plan.json'),
            },
            '2': {
                'body-shared': require('./new_illustration/variation_3/body-shared.json'),
                'body-congrats': require('./new_illustration/variation_3/body-congrats.json'),
                'body-customizeplan': require('./new_illustration/variation_3/body-customizeplan.json'),
                'habits-goal': require('./new_illustration/variation_3/habits-goal.json'),
                'habits-goalwell': require('./new_illustration/variation_3/habits-goalwell.json'),
                'habits-exercise-not-required-female': require('./new_illustration/variation_3/habits-exercise-not-required-female.json'),
                'habits-exercise-not-required-male': require('./new_illustration/variation_3/habits-exercise-not-required-male.json'),
                'habits-plan': require('./new_illustration/variation_3/habits-plan.json'),
                'habits-changes': require('./new_illustration/variation_3/habits-changes.json'),
                'habits-changes-male': require('./new_illustration/variation_3/habits-changes-male.json'),
                'habits-motivation': require('./new_illustration/variation_3/habits-motivation.json'),
                'habits-busy': require('./new_illustration/variation_3/habits-busy.json'),
                'nutrition-start': require('./new_illustration/variation_3/nutrition-start.json'),
                'nutrition-cbtfeedbackgreat': require('./new_illustration/variation_3/nutrition-cbtfeedbackgreat.json'),
                'nutrition-cbtfeedbackperfect': require('./new_illustration/variation_3/nutrition-cbtfeedbackperfect.json'),
                'nutrition-cbtfeedbackcuriosity': require('./new_illustration/variation_3/nutrition-cbtfeedbackcuriosity.json'),
                'nutrition-control': require('./new_illustration/variation_3/nutrition-control.json'),
                'nutrition-plan': require('./new_illustration/variation_3/nutrition-plan.json'),
            },
            '3': {
                'body-shared': require('./new_illustration/variation_4/body-shared.json'),
                'body-congrats': require('./new_illustration/variation_4/body-congrats.json'),
                'body-customizeplan': require('./new_illustration/variation_4/body-customizeplan.json'),
                'habits-goal': require('./new_illustration/variation_4/habits-goal.json'),
                'habits-goalwell': require('./new_illustration/variation_4/habits-goalwell.json'),
                'habits-exercise-not-required-female': require('./new_illustration/variation_4/habits-exercise-not-required-female.json'),
                'habits-exercise-not-required-male': require('./new_illustration/variation_4/habits-exercise-not-required-male.json'),
                'habits-plan': require('./new_illustration/variation_4/habits-plan.json'),
                'habits-changes': require('./new_illustration/variation_4/habits-changes.json'),
                'habits-changes-male': require('./new_illustration/variation_4/habits-changes-male.json'),
                'habits-motivation': require('./new_illustration/variation_4/habits-motivation.json'),
                'habits-busy': require('./new_illustration/variation_4/habits-busy.json'),
                'nutrition-start': require('./new_illustration/variation_4/nutrition-start.json'),
                'nutrition-cbtfeedbackgreat': require('./new_illustration/variation_4/nutrition-cbtfeedbackgreat.json'),
                'nutrition-cbtfeedbackperfect': require('./new_illustration/variation_4/nutrition-cbtfeedbackperfect.json'),
                'nutrition-cbtfeedbackcuriosity': require('./new_illustration/variation_4/nutrition-cbtfeedbackcuriosity.json'),
                'nutrition-control': require('./new_illustration/variation_4/nutrition-control.json'),
                'nutrition-plan': require('./new_illustration/variation_4/nutrition-plan.json'),
            },
        }[new_illustration] as {};
    }

    return {}
}

function getByTypeScale() {

    return {
        'body-gender': require('./type_scale/body-gender.json'),
        'body-congrats': require('./type_scale/body-congrats.json'),
        'habits-plan': require('./type_scale/habits-plan.json'),
        'body-shared': require('./type_scale/body-shared.json'),
        'body-customizeplan': require('./type_scale/body-customizeplan.json'),
        'habits-lifestyle': require('./type_scale/habits-lifestyle.json'),
        'habits-goal': require('./type_scale/habits-goal.json'),
        'habits-changes-male': require('./type_scale/habits-changes-male.json'),
        'habits-exercise-not-required-male': require('./type_scale/habits-changes-male.json'),
        'habits-motivation': require('./type_scale/habits-motivation.json'),
        'habits-relatebusy': require('./type_scale/habits-relatebusy.json'),
        'habits-relateplan': require('./type_scale/habits-relateplan.json'),
        'habits-relatemotivation': require('./type_scale/habits-relatemotivation.json'),
        'habits-busy': require('./type_scale/habits-busy.json'),
        'nutrition-cbt': require('./type_scale/nutrition-cbt.json'),
        'nutrition-cbtfeedbackgreat': require('./type_scale/nutrition-cbtfeedbackgreat.json'),
        'nutrition-control': require('./type_scale/nutrition-control.json'),
        'nutrition-lunch': require('./type_scale/nutrition-lunch.json'),
        'nutrition-frequency': require('./type_scale/nutrition-frequency.json'),
        'nutrition-pace': require('./type_scale/nutrition-pace.json'),
        'nutrition-plan': require('./type_scale/nutrition-plan.json'),
        'nutrition-cbtfeedbackcuriosity': require('./type_scale/nutrition-cbtfeedbackcuriosity.json'),
        'body-breastfeeding': require('./type_scale/body-breastfeeding.json'),
        'habits-exercise-not-required-female': require('./type_scale/habits-exercise-not-required-female.json'),
        'habits-sleep': require('./type_scale/habits-sleep.json')
    }
}

export const uiChangeIter1PageSequence: handerType = (value) => {
    const { new_illustration } = value;

    const byTypeScale = getByTypeScale();
    const byNewIllustration = getByNewIllustration(new_illustration);

    return merge(byNewIllustration, byTypeScale) as {};
};
