import {useState} from "react";
import {localStorageService} from "@ruby-labs/ruby-web-common";

export const BODY_GOAL: any = {
    gainMuscle: 'gain muscle',
    loseWeight: 'lose weight'
}

export const useLandingRedirection = () => {
    const [ isQuery, setIsQuery ] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const bodyGoal = urlParams.get('bodyGoal');

    if(Object.keys(BODY_GOAL).indexOf(bodyGoal as string) !== -1) {
        setIsQuery(true);
        localStorageService.setItem('body-goal',BODY_GOAL[bodyGoal || 'lose weight']);
    }

    return {
        isQuery: isQuery
    }
}
