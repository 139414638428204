import React, { useEffect } from "react";
import {withOptimizely, ReactSDKClient} from '@optimizely/react-sdk';

import { DefaulHome } from "./components/DefaultHome";
import { GenericInstance } from '../../store/generic_store';
import {componentConfigFile} from "../../utils/component_config_file";
import {Generic} from "../../components/generic";
import {Layout} from "../../components/layout";

import {useHomePage} from "./home_page.hook";
import {useHistory} from "react-router-dom";
import {useLandingRedirection} from "./landing_redirection.hook";

import {useStyles} from "./styles";

type HomeType = {
    optimizely: ReactSDKClient | null
}

const NEXT_PAGE = '/body-idealweightlasttime';

export const Home = withOptimizely(({ optimizely }: HomeType) => {
    const history = useHistory();
    const { isQuery } = useLandingRedirection();

    const {
        layout, onSubmit,
        eulaEvent, privacyEvent, helpEvent,
        existConfigData, loadCofig
    } = useHomePage(optimizely);

    const { page } = useStyles();

    useEffect(() => {
        if (isQuery) {
            GenericInstance.startPreloadPage(NEXT_PAGE);
        }
        GenericInstance.startPreloadPage('/body-age');
    }, [])


    if (isQuery) {
        history.push(NEXT_PAGE);

        return null
    }

    if (existConfigData) {

        return (
            <div className={page}>
                <Layout isProgressBarActive={false}>
                    <Generic
                        pageConfigFile={layout}
                        componentConfigFile={componentConfigFile}
                    />
                </Layout>
            </div>
        )
    }

    if (!loadCofig && !existConfigData) {

        return (
            <DefaulHome
                {...{
                    onSubmit,
                    eulaEvent, privacyEvent,
                    helpEvent
                }}
            />
        )
    }

    return null;
});
