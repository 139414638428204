import React from "react";

import { ThemeProvider } from "@ruby-labs/ruby-web-common";

import { theme } from "../../theme";

import {withOptimizely} from '@optimizely/react-sdk';

const themes = { 0: theme };

export const CustomThemeProvider = withOptimizely(({ children, optimizely }: any) => {

    return (
        //@ts-ignore
        <ThemeProvider theme={themes[0]}>
            {children}
        </ThemeProvider>
    )
});
