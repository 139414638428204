export const theme = {
    palette: {
        light_gray: '#C5C5D1',
        black: '#202b47',
        error: '#FF5758'
    },
    primaryButton: ({ palette } : any) => ({
        width: '100%',
        height: 48,
        borderRadius: '8px',
        background: palette.primary,
        color: palette.light,
        transition: 'background .3s, color .3s, box-shadow 2s',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 16px',
        '& p': {
            fontWeight: '600!important',
            color: 'white',
            lineHeight: '26px!important'
        },
        '&:hover': {
            background: palette.primary_dark
        },
        '&:active': {
            boxShadow: palette.boxShadow
        }
    }),
    checkButton: ({ palette }: any) => ({
        display: 'flex',
        alignItems: 'center',
        height: 56,
        boxSizing: 'border-box',
        '&$selectedClass': {
            background: palette.light,
            border: `1px solid ${palette.primary}`,
            color: palette.black,

            '& $checkWrapper': {
                background: palette.primary
            }
        },
        '& $checkWrapper': {
            order: 1,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 24,
            width: 24,
            height: 24,
            marginRight: 16,
            background: palette.white
        },
        '& $checkIconCl': {
            width: 12,
            '& path': {
                color: palette.white
            }
        },
        '& $childrenStyle': {
            order: 2,
            paddingRight: '32px',
            textAlign: 'left',
            width: '100%'
        },
        '& $centeredChildren': {
            padding: 0,
            fontSize: '18px'
        }
    }),
    inputRootClass: () => ({
        color: '#121620'
    }),
    inputLabelActiveClass: () => ({
        color: '#868c99',
        top: -6,
        left: '0.75rem',
        fontSize: 11,
        fontWeight: 400,
    }),
    inputLabelClass: () => ({
        lineHeight: '24px',
        top: 4,
        fontSize: 18,
    }),
    inputErrorTextClass: () => ({
        padding: 0,
        lineHeight: '17px',
        fontSize: 11
    }),
    inputErrorClass: ({ palette }: any) => ({ color: palette.error }),
    typography: {
        title: {
            fontSize: 20,
            lineHeight: '28px',
            color: '#202B47',
            fontWeight: 600,
        },
        body1: {
            color: '#202B47',
            fontSize: '18px',
            lineHeight: '26px',
            fontWeight: 400,
            fontFamily: 'SF Pro Text',
            margin: 0,
            letterSpacing: '-0.0024em'
        }
    },

    overwrite: {
        selectedButton: {
            root: {
                padding: '16px 8px',
                '& p': {
                    fontSize: '18px',
                    lineHeight: '26px !important'
                }
            }
        }
    }
}
