import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    floatedBtn: {
        height: 128,
        width: '100%',
        maxWidth: 428,
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 16px',
        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 21.6%)',
        boxSizing: 'border-box'
    }
});
