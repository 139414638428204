import Amplitude from "amplitude-js";
// @ts-ignore
import queryString from "query-string";
import { localStorageService } from '@ruby-labs/ruby-web-common';
import {createInstance, enums} from "@optimizely/react-sdk";
import { funnelAB } from '../../components/app';
const USER_ID_KEY = 'userID';

const { LOG_LEVEL } = enums;
declare global {
    interface Window {
        optimizely: any;
    }
}

export interface AnalyticsServiceInterface {
    event: (name: string, params: any) => void;
    optyConfigRef: { [key: string]: any };
}

class AnalyticsService implements AnalyticsServiceInterface {
    public optyConfigRef: { [key: string]: any } = {};

    private useLog: boolean = window.location.origin.includes('join-dev') && window.location.origin.includes('localhost');
    public amplitude = Amplitude.getInstance();
    public optimizely = createInstance({
        sdkKey: process.env.REACT_APP_OPTIMIZELY_API_KEY as string,
        logLevel: this.useLog ? LOG_LEVEL.INFO : LOG_LEVEL.NOTSET,
    });

    updateConfigOpty = (cb?: (optyConfigRef: any) => void) => {
        this?.optimizely?.onReady().then(() => {
          this.optyConfigRef = funnelAB.features.reduce((prev: any, current: string) => {
            if (this?.optimizely.isFeatureEnabled(current)) {
              prev[current] = this?.optimizely.getFeatureVariables(current);
            }
            return prev;
          }, {});

          if (this?.optyConfigRef?.web_general_single_flow_layout?.layout == 0) {
            localStorageService.setItem('web_general_single_flow_layout', 0);
          } else {
            localStorageService.setItem('web_general_single_flow_layout', 1);
          }

          if (cb) cb(this?.optyConfigRef);
        });
      };

    get userId(): string {
        const value = localStorage.getItem(USER_ID_KEY);
        if (value) return value;

        return (this.userId = Math.random().toString(36).substring(2, 15));
    }

    set userId(value) {
        localStorage.setItem(USER_ID_KEY, value);
        this.setAmplitudeUserId(value);
    }

    setAmplitudeUserId(userId: any) {
        this.amplitude.setUserId(userId);
    }

    private initAmplitudeAndOptimizely() {
        this.amplitude.init(
            process.env.REACT_APP_AMPLITUDE_API_KEY as string,
            this.userId,
            {
                includeUtm: true,
                includeGclid: true,
                includeReferrer: true,
                userId: this.userId,
            },
            async () => {
                const { ACTIVATE } = enums.NOTIFICATION_TYPES;

                await this.optimizely?.onReady();

                const parsed = queryString.parse(window.location.search);
                this.amplitude.setUserProperties(parsed)

                this.optimizely.notificationCenter.addNotificationListener(
                    ACTIVATE,
                    (event: any = {}) => {
                        if (localStorage.getItem(event.experiment?.id)) {
                            return;
                        }

                        this.baseEvent('OptimizelyExperimentViewed', {
                            experimentId: event.experiment?.id,
                            experimentName: event.experiment?.key,
                            variationId: event.variation?.id,
                            variationName: event.variation?.key,
                        });

                        localStorage.setItem(event.experiment?.id, 'true');
                    }
                );
            }
        );
    }



    public baseEvent(name: string, params = {}) {
        const data = this.parseEventData(params);
        this.logEvent(name, data);
        try {
            this.amplitude.logEvent(name, data);
        } catch (e: any) {
            console.log(e)
        }

        return {data};
    }

    private parseEventData(params: any) {
        return Object.entries(params).reduce((d, [key, value]) => {
            if (!value) return d

            let _value = value

            switch(typeof _value) {
                case 'object': {
                    _value = JSON.stringify(_value)
                    break;
                }
            }

            return { ...d, [key]: _value }
        }, {})
    }
    private logEvent(name: string, data = {}) {
        if(this.useLog) return;

        console.log(" ")
        console.log(
            `%c[FIRED CLIENT EVENT] ${name}`,
            "color:orange; font-size: 16px; text-transform: capitalize;"
        );
        console.table(
            {
                "EVENT NAME": name,
                "EVENT DATA": Object.keys(data).length ? JSON.stringify(data) : '-'
            }
        )
        console.log(" ")
    }

    constructor() {
        this.initAmplitudeAndOptimizely();
    }

    sendedEvent: { [key: string]: string | undefined } = {};

    timer: any = null;

    async myltiEventProtection (eventName: string) {
        if (!eventName) throw new Error('not allowed event');

        if (this.sendedEvent[eventName]) {
            this.timer = setTimeout(() => {
                this.sendedEvent[eventName] = undefined;
            }, 100);

            throw new Error('not allowed event');
        }

        this.sendedEvent[eventName] = eventName;

        this.timer = setTimeout(() => {
            this.sendedEvent[eventName] = undefined;
        }, 100);

    }

    public async event(name: string, params = {}) {
        if (window.dataLayer) {
            window.dataLayer.push({ event: name, conversionValue: 0 });
        }
        this.myltiEventProtection(name).then(() => {
            void this.baseEvent(name, params)

            if (['OptimizelyExperimentViewed', 'OnboardingViewed'].includes(name)) return;

            try {
                this.optimizely?.onReady().then(() => {
                    this.optimizely.track(name, this.userId);
                });
            } catch (error: any) {
                this.optimizely?.onReady().then(() => {
                    this.optimizely.track(name, this.userId);
                });
            }
        });
    }
}

export const analytics_service = () => new AnalyticsService();
