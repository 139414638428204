import React, { useMemo } from 'react';
import shortid from 'shortid'

type GenericTypes = {
    pageConfigFile: any,
    componentConfigFile?: any
}

export const Generic = ({pageConfigFile, componentConfigFile}: GenericTypes) => {
    if (!pageConfigFile) return null;

    const order = getOrderElements(pageConfigFile)

    const GetComponent = ({ field }: any) => {
        return useMemo(
            () =>
                componentConfigFile[field]
                    ? componentConfigFile[field](pageConfigFile[field], pageConfigFile.path)
                    : null,
            [ field ]
        );
    };

    return (
        <>
            {
                order?.map((field: string) => (
                    <div key={shortid.generate()}>
                        <GetComponent field={field || ''}/>
                    </div>
                ))
            }
        </>
    )
}


function getOrderElements (pageConfigFile: any) {
    return pageConfigFile.order && pageConfigFile.order.length ? pageConfigFile.order : Object.keys(pageConfigFile)
}