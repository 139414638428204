import {createUseStyles} from 'react-jss'

export const useStyles = createUseStyles({
    content: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',

        '& button': {
            marginBottom: 12,
            transition: "none"
        },
        '& button:last-child': {
            marginBottom: 0
        },
        '&>div': {
            width: '100%',
            marginBottom: 12
        },
        '&>div:last-child': {
            marginBottom: 0
        },
        '& p': {
            fontWeight: 'normal'
        }
    },
    baseButton: {
        marginTop: 32
    }
})
