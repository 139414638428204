import React,  {useEffect} from 'react'
import shortid from 'shortid'

import { ButtonBaseLogic } from './wrappers/ButtonBaseLogic';
import { ButtonSelectLogic } from './wrappers/ButtonSelectLogic';
import { ButtonMultiSelectLogic } from './wrappers/ButtonMultiSelect';
import { isBaseButtonNotShowed, isSelectNotShowed } from "../../utils/buttons_conditions";
import { getOrderButtons } from "../../utils/buttons_getters";

import { styleParser } from "../../helpers/services/config_styles_parser.service";
import { useStyles } from './styles';

const ITEM_DELAY = 250;

export const ButtonFactory = ({ order, path, ...buttonsConfig }: any) => {
    const { content, baseButton } = useStyles();
    const buttonsOrder = getOrderButtons(order, buttonsConfig);

    useEffect(() => {
        const multiSelectButtons = buttonsConfig['multi_select'];

        if (multiSelectButtons) {
            setTimeout(() => {
            }, ITEM_DELAY * multiSelectButtons.length)
        }
    }, [buttonsConfig]);

    if (!buttonsOrder) return null


    return buttonsOrder?.map((buttonType: string) => {
        switch (buttonType) {
            case 'base': {
                if (isBaseButtonNotShowed(buttonsConfig, buttonType)) break;
                return <div
                    style={styleParser(buttonsConfig.style)}
                    key={shortid.generate()}
                    className={baseButton}
                >
                    <ButtonBaseLogic
                        path={path}
                        {...buttonsConfig[buttonType]}
                        className={content}
                    />
                </div>
            }
            case 'multi_select': {
                if (isSelectNotShowed(buttonsConfig, buttonType)) break;

                return <div
                    style={styleParser(buttonsConfig.style)}
                    key={shortid.generate()}
                    className={content}
                >
                    {buttonsConfig[buttonType]?.map((config: any) => (
                        <ButtonMultiSelectLogic
                            path={path}
                            {...config}
                        />
                    ))}
                </div>
            }
            case 'select': {
                if (isSelectNotShowed(buttonsConfig, buttonType)) break;
                return <div
                    style={styleParser(buttonsConfig.style)}
                    key={shortid.generate()}
                    className={content}
                >
                    {
                        buttonsConfig[buttonType]?.map((config: any) => (
                            <div >
                                <ButtonSelectLogic
                                    path={path}
                                    {...config}
                                    {...{pageValue: '', setPageValue: () => {}}}
                                />
                            </div>
                        ))
                    }
                </div>
            }
            default: return null
        }

        return null
    })
}
