import React from 'react';
import ReactDOM from 'react-dom';
import Index from './components/app';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { analytics_service } from "./helpers/services/analitics.service";
import { initValueProposition } from './helpers/vp_generator';

initValueProposition();
export const analytic = analytics_service();


ReactDOM.render(
    <React.StrictMode>
        <Index analytic={analytic}/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
