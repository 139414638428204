const CUSTOM_QUERY_DEFAULT_RESPONSE = () => ({})

export const getCustomQuery = (numberQuery: any) => {
    return {
        'lose weight': () => {
            return {
                // @ts-ignore
                ...numberQuery[localStorage.getItem('weight_unit_of_measurement_tab')],
                lessThen: {
                    value: localStorage.getItem('body-weight'),
                    errorMessage: 'The goal you chose is to lose weight. Make sure your goal weight is lower than your current weight.'
                }
            }
        },
        'gain muscle': () => {
            return {
                // @ts-ignore
                ...numberQuery[localStorage.getItem('weight_unit_of_measurement_tab')],
            }
        }
        // @ts-ignore
    }[localStorage.getItem('body-goal')] || CUSTOM_QUERY_DEFAULT_RESPONSE;
}
