import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASURMENT_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
});

export type PageConfigType = {
  path?: string;
  id?: string;
  variation?: string | null;
};

export class ConfigPageService {
  private db = firebaseApp.firestore();

  public getConfigPageByPathAndVariation(path: string, variation: string) {

    return this.db
      .collection('page_config')
      .where('path', '==', path.replace('/', ''))
      .where('variation', '==', variation || null)
      .get()
      .then((querySnapshot: any) => {
        const data = querySnapshot.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
        return data[0];
      });
  }

  public getProgressBar({ page_config_id, variation }: any) {
    if (!page_config_id) return { progress_bar: {} };

    return this.db
      .collection('funnel_variation')
      .where('page_config_id', '==', page_config_id || null)
      .where('variation', '==', variation || null)
      .get()
      .then((querySnapshot: any) => {
        const data = querySnapshot.docs.map((doc: any) => doc.data());
        return data[0];
      });
  }

  public getConfigPage({ path, id, variation }: PageConfigType) {
    return (
      this.db
        .collection('page_config')
        .where(path ? 'path' : firebase.firestore.FieldPath.documentId(), '==', path?.replace('/', '') || id || null)
        // .where('variation', '==', variation || null)
        .get()
        .then((querySnapshot: any) => {
          const data = querySnapshot.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
          return data[0];
        })
    );
  }

  public getAllMedia() {
    return (
      this.db
        .collection('page_config')
        .where('project_id', '==', '00upEV6ABd4qydDN6AlI')
        .where('thumbnails.img.src', '!=', null)
        .get()
        .then((querySnapshot: any) => {
          const data = querySnapshot.docs.map((doc: any) => doc.data());
          return data;
        })
    );
  }
}
