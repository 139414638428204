import React from "react";

import { useStyles } from "./styles";

type FloatedButtonProps = {
    floated?: boolean,
    children?: any
}

export const FloatedButton = ({ children, floated }: FloatedButtonProps) => {
    const { floatedBtn } = useStyles();

    if (!floated) return children;

    return (
        <div className={floatedBtn}>
            {children}
        </div>
    )
};
