export const generateUniqueID = (length: number = 32) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
    let result = [];
    
    const charlen = chars.length;
    
    for (var i = 0; i < length; i++) {
        result[i] = chars.charAt(Math.floor(Math.random() * charlen));
    }

    return result.join('');
}