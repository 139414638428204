import {makeAutoObservable} from "mobx"
import {createContext} from "react";
import {BackgroundLoadingImages} from './backgroundImages';

const webWorkerScript = `
  self.addEventListener('message', event => {
    const url = event.data;

    fetch(url, {
        method: 'GET',
        mode: 'no-cors',
        cache: 'default'
    }).then(response => response.blob()).then(blob => postMessage({url, blob})).catch((e) => {console.error(e)});
  })
`;

class PreloadImg {
    constructor() {
        makeAutoObservable(this);

        BackgroundLoadingImages().images.map((link: string) => this.loadImageWorker(link));

        this.worker.onmessage = (event: Object) => {
            // @ts-ignore
            const imgPath = event?.data?.url.replace(window.location.origin, '');

            // @ts-ignore
            this.img[imgPath] = URL.createObjectURL(event?.data?.blob);
        };
    }

    img: { [key: string]: any } = {};
    loadedDestination: any = [];

    private worker = new Worker(URL.createObjectURL(
        new Blob([webWorkerScript], { type: 'application/javascript' })
    ));

    private loadImageWorker(path: string) {
        const image = import(`../assets/${path}`);

        image.then((module) => {
            this.worker.postMessage(`${window.location.origin}${module.default}`);
        });
    }

    private getLinkFromConfig = (config: any): string | undefined => {
        return config?.thumbnails?.img?.path
    }

    private async loadImage(path: string) {
        try {
            if (!path || this.img[path]) return
            const image = await import(`../assets/${path}`)

            const loading = new Image();
            loading.src = image.default;

            this.img[path] = image;
        } catch (e) {
            console.log(e);
        }
    }

    getImageAsync = async (path: string) => {
        if(!path) return;
        if (!this.img[path]) void await this.loadImage(path)

        return this.img[path];
    }

    getImageByLocalPath = (path: string): Promise<string | undefined> => {
        if(!path) {
            return Promise.resolve(undefined);
        }

        return import(`../assets/${path}`)
            .then(module => this.getImageByPath(module.default));
    }

    getImageByPath = (path: string): string => {
        if(!path || !this.img[path]) {
            return path;
        }
        return this.img[path];
    }

    getImage = (path: string) => {
        if(!path) return;
        if (!this.img[path]) void this.loadImage(path)

        return this.img[path];
    }

    startPreloadImage = async (config: any, destination: string) => {
        try {
            const link: string | undefined = this.getLinkFromConfig(config)

            if (!link || this.img[link]) return;

            await this.loadImage(link)

            this.loadedDestination = [...this.loadedDestination, destination]
        } catch (e) {}
    }
}

export const PreloadImgInstance = new PreloadImg();
export const PreloadImgInstanceCTX = createContext(PreloadImgInstance);

