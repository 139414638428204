import {WrapperApi} from "./wrapper.api";

class RedirectApi extends WrapperApi{
    getRedirectUrl(url: string, query: string) {
        return fetch(`${process.env.REACT_APP_ABLE_API_URL}/api/v2/instagram?url=${url}`).then((res) =>
            res.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob])).replace(
                'blob:',
                ''
            );
            const link = document.createElement('a');
            const search = window.location.search;
            link.href = url + (search ? search + '&' + query : '?' + query);
            link.setAttribute('download', `sample.pdf`);
            document.body.appendChild(link);
            link.click();
            link?.parentNode?.removeChild(link);
            })
        );
    }
}

export const RedirectApiProvider = new RedirectApi();