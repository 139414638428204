import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
    header: {
        zIndex: 999,
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '56px',
        left: '0px',
        background: '#DEE5F9',
        position: 'fixed',
    },
    headerContent: {
        padding: '0 16px 0 2px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        height: '36px',
        background: '#FFFFFF',
        borderRadius: '20px',
        margin: '0px 10px',
    },
    headerAvatar: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        letterSpacing: '-0.0024em',
        color: '#066FDE',
        display: 'flex',
        borderRadius: '50%',
        width: '32px',
        height: '32px',
        background: '#EFF2FD',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px'
    },
    headerEmail: {
        maxWidth: '144px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.0008em',
        color: '#202B47',
    },
})