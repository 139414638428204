import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
    page: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        padding: '32px 16px',
        background: 'none',
        marginBottom: 0,
        maxWidth: '465px',
        margin: '0 auto',
        height: 'calc(100vh - 170px)',
        '& strong': {
            fontWeight: 600
        }
    },
    illustration: {
        width: '100%',
        height: '237px',
        position: "absolute",
        left: '0px',
        top: '0px',
        maxWidth: '465px',
        marginTop: '-96px'
    },
    logo: {
        marginBottom: 32,
        marginTop: 52,
        height: '48px',
        width: '100%',
        textAlign: "center"
    },
    content: {
        padding: '99px 0px 50px 0px',
        textAlign: 'center',
    },
    title: {
        fontFamily: 'SF Pro Display',
        fontSize: '23px !important',
        fontWeight: '600 !important',
        lineHeight: '28px !important',
        letterSpacing: 0.35,
        color: '#ffffff',
        marginTop: '32px !important',
        marginBottom: 26,
        textAlign: 'left'
    },
    text: {
        color: '#ffffff',
        fontFamily: 'Poly serif',
    },
    titleText: {
        marginBottom: 26
    },
    selectButton: {
        width: '100%',
        margin: '6px 0',
        transition: "none",

        '& p': {
            lineHeight: '20px',
        }
    },
    policy: {
        fontWeight: 'normal',
        marginTop: 74,
        color: '#202B47',
        '& a': {
            color: '#202B47'
        }
    },
    button: {
        width: '80%',
        marginTop: 35,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: 342
    },
    '@media (max-width: 338px)' : {
        textBreak: {
            display: 'none',
        }
    },
    footer: {
        height: 170,
        background: '#F0F0FF',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: "start"
    },
    disclaimer: {
        marginTop: 24,
        letterSpacing: '0.0008em',
        color: '#868C99'
    }
})