import React from 'react';
import { Input, TextNormalizer, ValidatedInput } from '@ruby-labs/ruby-web-common';

import { getCustomQuery } from "./query_generator";

import { useStyles } from "./styles";

export const BaseInput = ({ adornmentEnd, validated, tabKey, path, storeKey, inputType,...props }: any) => {
    const { rootValidatedInputClass } = useStyles();
    const inputProps = {
        adornmentEnd: TextNormalizer(
            adornmentEnd || '',
            {
                localStorageMode: true,
                tagWrappers: [['*', 'p'], ['$', 'div']]
            }
        ),
        ...props
    }

    if (validated) {
        const { numberQuery, hiddenSmallScreenErrorMessage } = props;
        const validatedInputClass = hiddenSmallScreenErrorMessage ? rootValidatedInputClass : '';
        const additionalQuery = numberQuery ? getCustomQuery(numberQuery)() : {};
        const { type, ...other } = validated;
        const validationQuery = {
            ...(validated.type ? { type : { ...validated.type, ...additionalQuery } } : {}),
            ...other
        };

        return  (
            <ValidatedInput
                rootValidatedInputClass={validatedInputClass}
                toggleValidationListener={tabKey}
                errorDelay
                errorDelayTime={400}
                validationQuery={validationQuery}
                {...inputProps}
                type={inputType}
            />
        )
    }

    return <Input {...inputProps} type={inputType} />;
}
