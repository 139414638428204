import React, {useContext, useEffect, useState} from 'react';
import xor from 'lodash/xor';

import {CheckButton, useLocalStorage} from "@ruby-labs/ruby-web-common";
import {isButtonClearShouldBeNotActive, isButtonTypeClear} from "../../../../utils/buttons_conditions";
import {PreloadImgInstanceCTX} from '../../../../store/preload_img_store';

export const ButtonMultiSelectLogic = ({ className, path, type, event,  ...props }: any) => {
    const { pageValue, setPageValue } = useLocalStorage({ key: path, defaultValue: '' });
    const { getImageAsync, getImage } = useContext(PreloadImgInstanceCTX);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const newValue = [props.value || props.label, props.value || props.label]

    const buttonClicked = () => {

        if (isButtonTypeClear(type) && pageValue.includes(newValue[0])) {
            setPageValue([]);
        } else if (isButtonTypeClear(type)) {
            setPageValue(pageValue ? [newValue[0]] : [newValue[0]]);
        } else {
            setPageValue(pageValue ? xor(pageValue, newValue) : [newValue[0]]);
        }
    }

    useEffect(() => {
        if (isButtonClearShouldBeNotActive(type, pageValue, newValue)) {
            setTimeout(() => {
                setPageValue(pageValue ? xor(pageValue, [newValue[0]]) : [newValue[0]]);
            }, 100)
        }
    }, [type, pageValue, newValue, setPageValue]);

    const [image, setImage] = useState({default: ''});

    async function fetchImages() {
        return await getImageAsync(props.img);
    }

    useEffect(() => {
        if(props.img){
            fetchImages().then(r => setImage(r));
        }
    }, [props.img, getImageAsync]);

    if (props.img) {
        props.img = image?.default || getImage(props.img)?.default;
    }

    return (
        <div className={className}>
            <CheckButton
                centered={true}
                selected={pageValue && pageValue.includes(props.value || props.label)}
                onClick={buttonClicked}
                {...props}
            />
        </div>
    );
}
