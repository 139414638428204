const images = [
    'svg/body-diet.svg',
    'svg/body-diet-musclegain.svg',
    'webp/progres_weigth_1.webp',
    'webp/progres_weigth_2.webp',
    'webp/progres_weigth_3.webp',
    'webp/Female-weightloss.webp',
    'webp/Woman-get-fit.webp',
    'webp/Woman-muscle.webp',
    'img/goal_map.png',
    'img/shield.png',
    'img/yoyo_chart.png',
    'svg/strawberry.svg',
    'svg/triangle.svg',
    'svg/rectangle.svg',
    'svg/pear.svg',
    'svg/hourglass.svg',
    'svg/ectomorph.svg',
    'svg/mesomorph.svg',
    'svg/endomorph.svg',
    'img/cheese.png',
    'svg/tofu.svg',
    'svg/fish.svg',
    'svg/meat.svg',
    'svg/eggs.svg',
    'svg/nuts.svg',
    'svg/peas.svg',
    'svg/sweet.svg',
    'svg/caffeine.svg',
    'svg/fries.svg',
    'svg/alcohol.svg',
    'svg/salt.svg',
    'svg/zucchini.svg',
    'svg/mushrooms.svg',
    'svg/tomatoes.svg',
    'svg/sweet.svg',
    'svg/caffeine.svg',
    'svg/fries.svg',
    'svg/alcohol.svg',
    'svg/salt.svg',
    'svg/graph.svg',
    'svg/peppers.svg',
    'svg/avocado.svg',
    'svg/pana.svg',
    'svg/body-customizeplan.svg',
    'svg/habits-changes.svg',
    'svg/female.svg',
    'svg/male.svg',
    'svg/habits-motivation.svg',
    'svg/habits-plan.svg',
    'svg/nutrition-cbtfeedbackgreat.svg',
    'svg/nutrition-control.svg',
    'svg/nutrition-start.svg',
    'svg/finisherv2/pana0.svg',
    'svg/finisherv2/pana1.svg',
    'svg/finisherv2/pana2.svg',
    'svg/finisherv2/pana3.svg',
    'svg/finisherv2/pana4.svg',
    'svg/finisherv2/pana5.svg',
    'svg/finisherv2/pana6.svg',
    'svg/finisherv2/pana7.svg',
    'svg/finisherv2/pana8.svg',
    'svg/finisherv2/pana9.svg',
    'svg/finisherv2/pana10.svg',
    'svg/logotype.svg',
    'svg/health_summary/bmi.svg',
    'svg/health_summary/hormonal.svg',
    'svg/health_summary/fire.svg',
    'svg/health_summary/tip.svg',
    'svg/logotype.svg',
    'svg/lifestyle_summary/motivation.svg',
    'svg/lifestyle_summary/sleep.svg',
    'svg/lifestyle_summary/eating.svg',
    'svg/lifestyle_summary/movement.svg',
    'svg/lifestyle_summary/point.svg',
    'svg/wait/sit-tight-var2.svg',
    'svg/wait/sit-tight-var3.svg',
    'svg/wait/sit-tight-var4.svg',
    'img/doctor.png',
    'img/lifestyle-person.png',
    'svg/body-type/male/body-type-male-ectomorph.svg',
    'svg/body-type/male/body-type-male-mesomorph.svg',
    'svg/body-type/male/body-type-male-ecto-mesomorph.svg',
    'svg/body-type/male/body-type-male-endomorph.svg',
    'svg/body-type/female/body-type-female-strawberry-variation-2.svg',
    'svg/body-type/female/body-type-female-triangle-variation-2.svg',
    'svg/body-type/female/body-type-female-rectangle-variation-2.svg',
    'svg/body-type/female/body-type-female-pear-variation-2.svg',
    'svg/body-type/female/body-type-female-hourglass-variation-2.svg',
    'svg/body-type/male/body-type-male-ectomorph-variation-2.svg',
    'svg/body-type/male/body-type-male-mesomorph-variation-2.svg',
    'svg/body-type/male/body-type-male-ectomesomorph-variation-2.svg',
    'svg/body-type/male/body-type-male-endomorph-variation-2.svg',
    'svg/body-type/female/body-type-female-strawberry-variation-3.svg',
    'svg/body-type/female/body-type-female-triangle-variation-3.svg',
    'svg/body-type/female/body-type-female-rectangle-variation-3.svg',
    'svg/body-type/female/body-type-female-pear-variation-3.svg',
    'svg/body-type/female/body-type-female-hourglass-variation-3.svg',
    'svg/body-type/male/body-type-male-ectomorph-variation-3.svg',
    'svg/body-type/male/body-type-male-mesomorph-variation-3.svg',
    'svg/body-type/male/body-type-male-ectomesomorph-variation-3.svg',
    'svg/body-type/male/body-type-male-endomorph-variation-3.svg',
    'svg/body-type/female/body-type-female-strawberry-variation-4.svg',
    'svg/body-type/female/body-type-female-triangle-variation-4.svg',
    'svg/body-type/female/body-type-female-rectangle-variation-4.svg',
    'svg/body-type/female/body-type-female-pear-variation-4.svg',
    'svg/body-type/female/body-type-female-hourglass-variation-4.svg',
    'svg/body-type/male/body-type-male-ectomorph-variation-4.svg',
    'svg/body-type/male/body-type-male-mesomorph-variation-4.svg',
    'svg/body-type/male/body-type-male-ectomesomorph-variation-4.svg',
    'svg/body-type/male/body-type-male-endomorph-variation-4.svg',
];

export const BackgroundLoadingImages = () => {
    return {
        images
    }
};
