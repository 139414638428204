import React from 'react';
import {useLocalStorage} from "@ruby-labs/ruby-web-common";
import {useStyles} from "./styles";

export const EmailHeader = ({classNames}: any) => {
    const {
        header,
        headerContent,
        headerAvatar,
        headerEmail
    } = useStyles()
    const { pageValue: email } = useLocalStorage({key: 'email', defaultValue: ''});
    const firstLetter = email ? email.split('')[0].toUpperCase() : '';

    return (
        <header className={`${header} ${classNames?.EmailHeaderHeader}`}>
            <div className={`${header} ${classNames?.EmailHeaderHeader}`}>
                <div className={headerContent}>
                    <div className={`${headerAvatar} ${classNames?.EmailHeaderHeaderAvatar}`}>{firstLetter}</div>
                    <div className={`${headerEmail} ${classNames?.EmailHeaderHeaderEmail}`}>{email}</div>
                </div>
            </div>
        </header>
    )
}
