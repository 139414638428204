import {createUseStyles} from 'react-jss'

export const useStyles = createUseStyles({
    mainContent: {
        maxWidth: 428,
        margin: ({props}: any) => props ? 0 : '0 auto 50px',
        position: 'relative',
        paddingBottom: ({ isScrollableContent }: any) => isScrollableContent ? 110 : 0
    }
})
