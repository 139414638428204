import React, { useContext } from 'react';
import { toJS } from 'mobx';
import { useHistory, useLocation } from 'react-router-dom';
import { ProgressStepper } from '@ruby-labs/ruby-web-common';

import { BaseContext } from '../../helpers/context/base.context';
import { useStyles } from './styles';
import { GenericInstanceCTX } from '../../store/generic_store';
import { observer } from 'mobx-react-lite';

export const ProgressBarLayout = observer(() => {
  const location = useLocation();
  const { preloadedProgress } = useContext(GenericInstanceCTX);
  const { destinationService, isShortOnboarding } = useContext(BaseContext);
  const { header, titleClass } = useStyles(isShortOnboarding);
  const { goBack } = useHistory();

  const progressInfo = toJS(preloadedProgress)[location.pathname.replace('/', '') as string];

  if (!progressInfo?.percent) return null;

  const parsedLabel = destinationService.parse({
    destination: progressInfo.label || '',
  });
  const parsedPercentInfo = destinationService.parse({
    destination: progressInfo.percent || '',
  });

  const oneThird = 100 / 3;
  const parsedPercent = oneThird * (Number(progressInfo.section) - 1) + (oneThird * Number(parsedPercentInfo)) / 100;


  return (
    <div className={header}>
       <ProgressStepper
            titleClass={titleClass}
            colors={[
                '#4B46EC',
                '#FE8F20',
                '#04A777'
            ]}
            percents={parsedPercent}
            steps={3}
            title={parsedLabel}
            backButtonOutlined={true}
            handleBack={goBack}
        />
    </div>
  );
});
