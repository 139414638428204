import { localStorageService } from "@ruby-labs/ruby-web-common";

const LOCALSTORADGE_WATCHING_KEY = "body-gender";
const watchVP = localStorageService.intitWatchVP(LOCALSTORADGE_WATCHING_KEY);

export const BodyGenderInitVP = () => {
    watchVP([
        (value: string) => {
            const newKey = "habits-lifestyle-gender";
            const newValue = {
                'Male': 'Men',
                'Female': 'Women'
            }[value];
            localStorageService.setItem(newKey, newValue);
        },
    ]);
};
