import keys from 'lodash/keys';

import { destinationService } from "../../components/app";

export const styleParser = (styles: any = {}) => {
    return keys(styles).reduce((obj: any, key: any) => {
        const styleValue = styles[key];

        obj[key] = typeof styleValue === "number"
            ? styleValue
            : destinationService.parse({ destination: styles[key] ?? '' });

        return obj;
    }, {});
}
