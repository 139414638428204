export const ROUTES_WITHOUT_MARGINS = ['/finisher'];
export const ROUTES_WITH_PROGRESS_BAR = ['/habits-changes', '/nutrition-source'];
export const ROUTES_WITH_HEADER = [
    '/addons',
    '/addon-discount',
    '/congrats',
    '/trialskip',
    '/wait',
    '/trial-pay',
]
export const ROUTES_WITH_SCROLL = [
    '/body-risk',
    '/nutrition-donteat',
    '/nutrition-bad',
    '/nutrition-vegetables',
    '/nutrition-proteins'
];
