import React, {useEffect, lazy, Suspense} from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";

import { OptimizelyProvider } from "@optimizely/react-sdk";
import { versionWatcher, DestinationService, TextNormalizer } from "@ruby-labs/ruby-web-common";

import { MainContent } from "../main_content";
import { CustomThemeProvider } from "../theme_provider";
import { ScrollToTop } from "../scroll_to_top";

import { OPTIMIZELY_ATTRIBUTES } from "../../constants/base";

import { BaseContext } from "../../helpers/context/base.context";
import { useCountryLocation } from "../../helpers/hooks/use_location.hook";
import { useLoadScript } from "../../helpers/hooks/use_load_script";
import { FunnelABTestingService } from "../../helpers/funnel_ab";
import { onboardingGeneralLayout } from "../../helpers/funnel_ab/web_general_onboarding_layout/handler";
import { uiChangeIter1PageSequence } from "../../helpers/funnel_ab/ui_change_iter_1_page_sequence/hundler";

import { Home, GenericPage } from "../../pages";

export const destinationService = new DestinationService(
  (text: string): string => {
    return TextNormalizer(text, {
      localStorageMode: true,
      tagWrappers: [
        ["*", "p"],
        ["$", "div"],
      ],
    });
  }
);

export const funnelAB = new FunnelABTestingService(
  ['web_general_onboarding', 'ui_change_iter_1'],
  {
    web_general_onboarding: onboardingGeneralLayout,
    ui_change_iter_1: uiChangeIter1PageSequence
  }
);

const ReactLazyPreload = (importStatement: () => Promise<any>) => {
  const Component = lazy(importStatement);

  // @ts-ignore
  Component.preload = importStatement;

  return Component;
};

const Payment = ReactLazyPreload(() => import("../../pages/subscribe/paymnet"));
const BodyHeight = ReactLazyPreload(() => import("../../pages/body_height"));
const BuildingPlan = ReactLazyPreload(() => import("../../pages/building_plan"));
const GoodHands = ReactLazyPreload(() => import("../../pages/finisher_v2/good_hands"));
const OurPrograms = ReactLazyPreload(() => import("../../pages/finisher_v2/our_programs"));
const RestrictiveDiets = ReactLazyPreload(() => import("../../pages/finisher_v2/restrictive_diets"));
const FinisherV2 = ReactLazyPreload(() => import("../../pages/finisher_v2"));
const BodyUnderscoreLoading = ReactLazyPreload(() => import("../../pages/body_undescore_loading"));
const TrialPay = ReactLazyPreload(() => import("../../pages/trial_pay"));
const Congrats = ReactLazyPreload(() => import("../../pages/congrats"));
const Access = ReactLazyPreload(() => import("../../pages/access"));
const Addons = ReactLazyPreload(() => import("../../pages/addons"));
const Finisher = ReactLazyPreload(() => import("../../pages/finisher"));
const HabitsWeightChart = ReactLazyPreload(() => import("../../pages/habits_weightchart"));
const BodyType = ReactLazyPreload(() => import("../../pages/body_type"));
const BodyWebHealthReport = ReactLazyPreload(() => import("../../pages/body-webhealthreport"));
const NutritionWebHealthReport = ReactLazyPreload(() => import("../../pages/nutrition-webhealthreport"));
const BodyDiet = ReactLazyPreload(() => import("../../pages/body_diet"));
const TrialSkip = ReactLazyPreload(() => import("../../pages/trial_skip"));
const NutritionVegetables = ReactLazyPreload(() => import("../../pages/nutrition_vegetables"));
const NutritionProteins = ReactLazyPreload(() => import("../../pages/nutrition_proteins"));
const NutritionBad = ReactLazyPreload(() => import("../../pages/nutrition_bad"));
const HabitsWeightChartTest = ReactLazyPreload(() => import("../../pages/habits_weightcharttets"));
const Subscribe = ReactLazyPreload(() => import("../../pages/subscribe"));
const Email = ReactLazyPreload(() => import("../../pages/email"));
const NutritionDietchart = ReactLazyPreload(() => import("../../pages/nutrition_dietchart"));
const NutritionMindfulEatingAnswer = ReactLazyPreload(() => import("../../pages/nutrition_mindful_eating_answer"));
const Wait = ReactLazyPreload(() => import("../../pages/wait"));

export const ableCustomRoutes = [
  {
    path: "/wait",
    exact: true,
    component: Wait,
  },
  {
    path: "/nutrition-mindful-eating-answer",
    exact: true,
    component: NutritionMindfulEatingAnswer,
  },
  {
    path: "/nutrition-dietchart",
    exact: true,
    component: NutritionDietchart,
  },
  {
    path: "/email",
    exact: true,
    component: Email,
  },
  {
    path: "/subscribe",
    exact: true,
    component: Subscribe,
  },
  {
    path: "/habits-weightchart2",
    exact: true,
    component: HabitsWeightChartTest,
  },
  {
    path: "/nutrition-bad",
    exact: true,
    component: NutritionBad,
  },
  {
    path: "/nutrition-proteins",
    exact: true,
    component: NutritionProteins,
  },
  {
    path: "/nutrition-vegetables",
    exact: true,
    component: NutritionVegetables,
  },
  {
    path: "/trialskip",
    exact: true,
    component: TrialSkip,
  },
  {
    path: "/body-diet",
    exact: true,
    component: BodyDiet,
  },
  {
    path: "/nutrition-webhealthreport",
    exact: true,
    component: NutritionWebHealthReport,
  },
  {
    path: "/body-webhealthreport",
    exact: true,
    component: BodyWebHealthReport,
  },
  {
    path: "/body-type",
    exact: true,
    component: BodyType,
  },
  {
    path: "/habits-weightchart1",
    exact: true,
    component: HabitsWeightChart,
  },
  {
    path: "/finisher",
    exact: true,
    component: Finisher,
  },
  {
    path: "/addon-discount",
    exact: true,
    component: Addons,
  },
  {
    path: "/addons",
    exact: true,
    component: Addons,
  },
  {
    path: "/access",
    exact: true,
    component: Access,
  },
  {
    path: "/congrats",
    exact: true,
    component: Congrats,
  },
  {
    path: "/trial-pay",
    exact: true,
    component: TrialPay,
  },
  {
    path: "/body-loading",
    exact: true,
    component: BodyUnderscoreLoading,
  },
  {
    path: "/loading",
    exact: true,
    component: BuildingPlan,
  },
  {
    path: "/loading-story",
    exact: true,
    component: FinisherV2,
  },
  {
    path: "/yo-yo-effect",
    exact: true,
    component: RestrictiveDiets,
  },
  {
    path: "/nutrition-experts",
    exact: true,
    component: OurPrograms,
  },
  {
    path: "/strides-towards-goals",
    exact: true,
    component: GoodHands,
  },
  {
    path: "/subscription-checkout",
    exact: true,
    component: Payment,
  },
  {
    path: "/body-height",
    exact: true,
    component: BodyHeight,
  }
];

function Index({ analytic }: any) {
  const { country } = useCountryLocation();
  const history = useHistory();

  useLoadScript();

  useEffect(() => {
    localStorage.setItem('weight_height_order', '2')
  }, [])

  const userId = new URLSearchParams(window.location.search).get('userId') || '';

  useEffect(() => {
    if (userId) {
      localStorage.setItem('userID', userId);
      analytic.userId = userId;
    }
  }, [userId]);

  void versionWatcher(
    process.env.REACT_APP_VERSION as string,
    () => {
      history?.push('/');
    }
  )

  const funnelAB = new FunnelABTestingService(
    ['web_general_onboarding', 'ui_change_iter_1'],
    {
      web_general_onboarding: onboardingGeneralLayout,
      ui_change_iter_1: uiChangeIter1PageSequence
    }
  );

  useEffect(() => {
    analytic.updateConfigOpty();
  }, []);

  return (

    <OptimizelyProvider
      optimizely={analytic.optimizely}
      user={{
        id: analytic.userId,
        attributes: OPTIMIZELY_ATTRIBUTES,
      }}
    >
      <BaseContext.Provider
        value={{
          analytic,
          country,
          funnelAB,
          destinationService,
        }}
      >
        <CustomThemeProvider>
          <Router>
            <ScrollToTop />
            <MainContent>
              <Switch>
                <Route path="/strides-towards-goals">
                  <Suspense fallback={null}>
                    <GoodHands />
                  </Suspense>
                </Route>
                <Route path="/nutrition-experts">
                  <Suspense fallback={null}>
                    <OurPrograms />
                  </Suspense>
                </Route>
                <Route path="/yo-yo-effect">
                  <Suspense fallback={null}>
                    <RestrictiveDiets />
                  </Suspense>
                </Route>
                <Route path="/loading-story">
                  <Suspense fallback={null}>
                    <FinisherV2 />
                  </Suspense>
                </Route>
                <Route path="/loading">
                  <Suspense fallback={null}>
                    <BuildingPlan />
                  </Suspense>
                </Route>
                <Route path="/about">custom route</Route>
                <Route path="/body-loading">
                  <Suspense fallback={null}>
                    <BodyUnderscoreLoading />
                  </Suspense>
                </Route>
                <Route path="/trial-pay">
                  <Suspense fallback={null}>
                    <TrialPay />
                  </Suspense>
                </Route>
                <Route path="/congrats">
                  <Suspense fallback={null}>
                    <Congrats />
                  </Suspense>
                </Route>
                <Route path="/access">
                  <Suspense fallback={null}>
                    <Access />
                  </Suspense>
                </Route>
                <Route path="/addons">
                  <Suspense fallback={null}>
                    <Addons />
                  </Suspense>
                </Route>
                <Route path="/addon-discount">
                  <Suspense fallback={null}>
                    <Addons />
                  </Suspense>
                </Route>
                <Route path="/finisher">
                  <Suspense fallback={null}>
                    <Finisher />
                  </Suspense>
                </Route>
                <Route path="/habits-weightchart1">
                  <Suspense fallback={null}>
                    <HabitsWeightChart />
                  </Suspense>
                </Route>
                <Route exact path="/body-type">
                  <Suspense fallback={null}>
                    <BodyType />
                  </Suspense>
                </Route>
                <Route exact path="/body-webhealthreport">
                  <Suspense fallback={null}>
                    <BodyWebHealthReport />
                  </Suspense>
                </Route>
                <Route exact path="/nutrition-webhealthreport">
                  <Suspense fallback={null}>
                    <NutritionWebHealthReport />
                  </Suspense>
                </Route>
                <Route exact path="/body-diet">
                  <Suspense fallback={null}>
                    <BodyDiet />
                  </Suspense>
                </Route>
                <Route exact path="/trialskip">
                  <Suspense fallback={null}>
                    <TrialSkip />
                  </Suspense>
                </Route>
                <Route exact path="/nutrition-vegetables">
                  <Suspense fallback={null}>
                    <NutritionVegetables />
                  </Suspense>
                </Route>
                <Route exact path="/nutrition-proteins">
                  <Suspense fallback={null}>
                    <NutritionProteins />
                  </Suspense>
                </Route>
                <Route exact path="/nutrition-bad">
                  <Suspense fallback={null}>
                    <NutritionBad />
                  </Suspense>
                </Route>
                <Route exact path="/habits-weightchart2">
                  <Suspense fallback={null}>
                    <HabitsWeightChartTest />
                  </Suspense>
                </Route>
                <Route exact path="/subscribe">
                  <Suspense fallback={null}>
                    <Subscribe />
                  </Suspense>
                </Route>
                <Route exact path="/subscription-checkout">

                  <Suspense fallback={null}>
                    <Payment />
                  </Suspense>

                </Route>
                <Route exact path="/email">
                  <Suspense fallback={null}>
                    <Email />
                  </Suspense>
                </Route>
                <Route exact path="/body-height">
                  <Suspense fallback={null}>
                    <BodyHeight />
                  </Suspense>
                </Route>
                <Route exact path="/nutrition-dietchart">
                  <Suspense fallback={null}>
                    <NutritionDietchart />
                  </Suspense>
                </Route>
                <Route exact path="/nutrition-mindful-eating-answer">
                  <Suspense fallback={null}>
                    <NutritionMindfulEatingAnswer />
                  </Suspense>
                </Route>
                <Route exact path="/wait">
                  <Suspense fallback={null}>
                    <Wait />
                  </Suspense>
                </Route>
                <Route path="/:path">
                  <GenericPage />
                </Route>
              </Switch>
            </MainContent>
            <Route exact path="/">
              <Home />
            </Route>
          </Router>
        </CustomThemeProvider>
      </BaseContext.Provider>
    </OptimizelyProvider>
  );
}

export default Index;
