import {createUseStyles} from 'react-jss'

export const useStyles = createUseStyles({
    content: {
        textAlign: 'center',
        padding: ({ isProgressBarActive, isNextButtonExist }: any) => {
            return   !isProgressBarActive ? 0 : `32px 16px${isNextButtonExist ? ' 128px' : ''}`
        }
    },
    "@media (max-width: 365px)": {
        content: {
            "&>div": {
                "& >p[class^='title'], &>p[class*=' title'], & >p[class^='body'], &>p[class*=' body']": {
                    '& >br': {
                        display: 'none',
                    },
                    '& >span br': {
                        display: 'none',
                    }
                },
            },
        },
    },
})
