import { localStorageService, useLocalStorage } from "@ruby-labs/ruby-web-common";
import queryString from "query-string";
import { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { BaseContext } from "../../helpers/context/base.context";
import { useRedirect } from "../../helpers/hooks/redirect.hook";
import { ConfigPageService } from "../../helpers/services/config_page.service";
import { iOSversion } from "../../utils/checkIosVersion";

const pageService = new ConfigPageService();

const goalValues: any = [
    {
        "label": "Lose weight",
        "value": "lose weight",
    },
    {
        "label": "Gain muscle",
        "value": "gain muscle",
    },
    {
        "label": "Get fit",
        "value": "lose weight",
    }
]

export const useHomePage = (optimizely: any) => {
    const history = useHistory();
    const { analytic } = useContext(BaseContext);
    const { handleRedirectToBrowser, isWeb, setCount, count } = useRedirect();
    const { variation = '', goal = '', categories = '' }: any = queryString.parse(window.location.search);
    const { pageValue, setPageValue: setGoal }: any = useLocalStorage({ key: 'body-goal', defaultValue: '' });
    const { pageValue: bodyGoalLabel, setPageValue: setBodyGoalLabel }: any = useLocalStorage({ key: 'body-goal-label', defaultValue: '' });
    const [layout, setLayout] = useState<any>({})
    const [loadCofig, setLoadingCofig] = useState<boolean>(true)

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        localStorage.setItem('utm_source', searchParams.get('utm_source') || '');

        optimizely?.onReady().then(() => {
            const infoVariation = optimizely.getFeatureVariables(categories);
            const isEnabledVariation = optimizely.isFeatureEnabled(categories);

            pageService.getConfigPageByPathAndVariation('home', (isEnabledVariation && infoVariation.variation) || variation ||  '' as string).then(
                (res: any) => {
                    if (res) {
                        setLayout(res)
                    }
                }
            ).finally(() => {
                setLoadingCofig(false);
            })

            if (!window.location.pathname.includes('/body-idealweightlasttime')) {
                const value = goalValues.find((item: any) => item.value === goal);
                localStorageService.setItem('body-goal', value?.value || 'lose weight');
            }
        }, [])
    }, []);

    return {
        layout,
        loadCofig,
        pageValue,
        onSubmit: (inputGoal: any) => {
            let goal = {
                "label": "Lose weight",
                "value": "lose weight",
            }
            if (inputGoal) {
                goal = inputGoal
            }

            setGoal(goal.value);
            setBodyGoalLabel(goal.label);

            if (count === 1 || !isWeb || iOSversion() >= 15) {
                analytic?.event("WebStartButtonClicked", { redirect: "no", value: goal.value });
                setTimeout(() => {
                    return history.push({
                        pathname: '/body-age',
                        search: window.location.search
                    });
                }, 100)

                return;
            }

            if(isWeb) {
                setCount(1)
                setTimeout(() => {
                    handleRedirectToBrowser(goal.value);
                    analytic?.event("WebStartButtonClicked", { redirect: "yes", value: goal });
                }, 100)

                return;
            }
        },
        handleRedirectToBrowser,
        eulaEvent: () => analytic?.event("WebStartEulaClicked"),
        privacyEvent: () => analytic?.event("WebStartPrivacyClicked"),
        helpEvent: () => analytic?.event("WebStartSupportClicked"),
        existConfigData: Object.keys(layout).length
    }
}
