import { localStorageService } from "@ruby-labs/ruby-web-common";

const LOCALSTORADGE_WATCHING_KEY = "body-goal";
const watchVP = localStorageService.intitWatchVP(LOCALSTORADGE_WATCHING_KEY);

export const BodyGoalInitVP = () => {
    watchVP([
        (value: string) => {
            const newKey = "habits-lifeevents-goal";
            const newValue = {
                'gain muscle': 'muscle loss',
                'lose weight': 'weight gain'
            }[value];
            localStorageService.setItem(newKey, newValue);
        },
        (value: string) => {
            const newKey = "habits-sleep-goal";
            const newValue = {
                'gain muscle': 'muscle gain',
                'lose weight': 'weight loss'
            }[value];
            localStorageService.setItem(newKey, newValue);
        },
        (value: string) => {
            const newKey = "nutrition-smallgoal-goal";
            const newValue = {
                'gain muscle': 'muscle gain',
                'lose weight': 'weight loss'
            }[value];
            localStorageService.setItem(newKey, newValue);
        },
        (value: string) => {
            const newKey = "body-diet-goal";
            const newValue = {
                'gain muscle': 'gained muscle',
                'lose weight': 'lost weight'
            }[value];
            localStorageService.setItem(newKey, newValue);
        },
        (value: string) => {
            const newKey = "body-shared-goal";
            const newValue = {
                'gain muscle': 'Muscle gain',
                'lose weight': 'Weight loss'
            }[value];
            localStorageService.setItem(newKey, newValue);
        },
    ]);
};
