import {useEffect} from "react";
import { useLocalStorage } from "@ruby-labs/ruby-web-common";
import { ShareInfoProvider } from "../../api/share_info";

const CUBA_KEY = 'CU';
const US_KEY = 'US';

export const useCountryLocation = () => {
    const {
        pageValue: country,
        setPageValue: setCountry
    } = useLocalStorage({
        key: 'utm_country',
        defaultValue: ''
    });

    useEffect(() => {
        if (country) return;

        ShareInfoProvider.getUserData().then((res: any) => {
            const countryCode = res?.countryCode;
            if (countryCode) {
                const code = countryCode === CUBA_KEY ? US_KEY : countryCode;
                setCountry(code);
            }
        });
    }, []);

    return {
        country
    }
}
