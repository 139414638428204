import { useEffect } from 'react';
import {generateUniqueID} from "../../utils/get_unique_id";

const useLoadScript = () => {
  useEffect(() => {
    const kountSessionID = generateUniqueID();
    localStorage.setItem('kount_session_id', kountSessionID)
    const script1 = document.createElement('script');

    script1.src = `${process.env.REACT_APP_DATA_COLLECTOR_URL}collect/sdk?m=100261&s=${kountSessionID}`;
    script1.async = true;

    document.body.appendChild(script1);    

    return () => {
      document.body.removeChild(script1);
    }
  }, []);
};

export { useLoadScript };