export const OPTIMIZELY_ATTRIBUTES = {
    audience: `platform = "web"`,
    platform: "web"
};

export const MARGIN_AUTO = {
    marginLeft: 'auto',
    marginRight: 'auto'
}

export const BASIC_FUNNEL_VARIATION_KEY_NAME = 'global_funnel_variation';