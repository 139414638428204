import {useLocation, useHistory} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import { useLocalStorage } from "@ruby-labs/ruby-web-common";

const HOME_ROUTE = '/'

const getLastVisitedPage = (availableRoutersList: Array<string>) => availableRoutersList[availableRoutersList.length - 1]
const isNotStartedPageAndNotIncludeInList = (
    availableRoutersList: Array<string>,
    pathname: string, startPage: any
) => !availableRoutersList.includes(pathname) && startPage.current.pathname !== pathname

export const useAccessPageChecking = () => {
    const {pathname} = useLocation();
    const history = useHistory();
    const startPage = useRef({pathname});
    const [routerAvailable, doRouterAvailable] = useState(false);
    const {
        pageValue: availableRoutersList,
        setPageValue: addAvailableRoute
    } = useLocalStorage({
        key: 'available_routes',
        defaultValue: ['/']
    });

    useEffect(() => {
        if (availableRoutersList?.includes(pathname)) {
            doRouterAvailable(true);
        } else if (pathname === HOME_ROUTE && !availableRoutersList?.includes(pathname)) {
            addAvailableRoute([pathname]);
            doRouterAvailable(true);
        } else {
            history.push(getLastVisitedPage(availableRoutersList));
            // window.location.pathname = getLastVisitedPage(availableRoutersList)
        }
    }, []);

    useEffect(() => {
        if (isNotStartedPageAndNotIncludeInList(availableRoutersList, pathname, startPage)) {
            addAvailableRoute([...availableRoutersList, pathname])
        }
    }, [pathname])

    return {
        routerAvailable
    }
}
