import React from "react";
import shortid from 'shortid';

import {
    SelectButton,
    Typography,
    TypographyVariants,
    useLocalStorage
} from "@ruby-labs/ruby-web-common";

import fishImg from "../../../assets/webp/fish_healthy.webp";
import logoIcon from "../../../assets/svg/logo.svg";

import { useStyles } from "../styles";

const goalValues: any = [
    {
        "label": "Lose weight",
        "value": "lose weight",
    },
    {
        "label": "Gain muscle",
        "value": "gain muscle",
    },
    {
        "label": "Get fit",
        "value": "lose weight",
    }
]


export const DefaulHome = ({ onSubmit, eulaEvent, privacyEvent, helpEvent }: any) => {
    const {
        page, content, illustration,
        logo, selectButton, policy,
        titleText, disclaimer
    } = useStyles();

    const { pageValue: bodyGoalLabel }: any = useLocalStorage({ key: 'body-goal-label', defaultValue: '' });

    return (
        <div className={page}>
            <img src={fishImg} alt="Fish" className={illustration}/>
            <div className={content}>
                <div>
                    <div className={logo}>
                        <img
                            src={logoIcon}
                            alt="Able logo"
                        />
                    </div>
                    <Typography variant={TypographyVariants.title} className={titleText}>
                        What is your goal?
                    </Typography>
                    {
                        goalValues.map((goal: any) => (
                            <SelectButton
                                key={shortid.generate()}
                                className={selectButton}
                                centered={true}
                                selected={goal.label === bodyGoalLabel}
                                onClick={() => onSubmit(goal)}
                                {...goal}
                            />
                        ))
                    }
                </div>
                <Typography variant={TypographyVariants.label} className={policy}>
                    By continuing you agree to our
                    <a
                        href="https://ableapp.helpdocs.io/article/z5kgusnalq-eula"
                        target='_blank'
                        rel='noreferrer nofollow'
                        onClick={eulaEvent}
                    > EULA</a> and
                    <a
                        href="https://help.ableapp.com/article/buaojstyqj-privacy-notice"
                        target='_blank'
                        rel='noreferrer nofollow'
                        onClick={privacyEvent}
                    > Privacy Notice</a>. Have a question? Reach our friendly support team <a
                        href="https://help.ableapp.com/"
                        target='_blank'
                        rel='noreferrer nofollow'
                        onClick={helpEvent}
                    >
                        here
                    </a>.
                </Typography>
                <Typography variant={TypographyVariants.label} className={disclaimer}>
                    Information provided is not intended to be a substitute
                    for medical advice, diagnosis, or treatment. Always
                    seek the advice of your physician regarding any
                    medical condition and before undertaking a new
                    health care regimen.
                </Typography>
            </div>
        </div>
    )
};
